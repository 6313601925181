<template>
  <EntityList
    ref="activeList"
    :config="config"
    :columns="columns"
    :custom-query="{ user: parentEntity.id }"
    :formatted-columns="formattedColumns"
    :form-fields="formFields"
    :extra-validation="extraValidation"
    authority="Users_User"
    embedded
    hide-edit
    @entityUpdated="updateEntity"
  />
</template>

<script>
import EntityList from '@/layouts/entity/EntityList.vue'
import storeModule from '../userPaymentsStoreModule'

export default {
  components: {
    EntityList,
  },
  props: {
    module: {
      type: String,
      required: true,
    },
    parentEntity: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      entity: {},
      paymentMethodOptions: [],
      subscriptionOptions: [],
      congressOptions: [],
      bankAccountOptions: [],
    }
  },
  computed: {
    userData() {
      return this.$store.getters['app-common/userData']
    },
    config() {
      return {
        store: storeModule,
        module: 'payments',
        endpoint: 'payments',
        route: 'user-payments',
        title: {
          single: this.$t('Payment Add'),
          plural: this.$t('Payments'),
        },
      }
    },
    columns() {
      return [
        { key: 'id', label: this.$t('id'), sortable: true },
        { key: 'amount', label: this.$t('Amount'), sortable: true },
        {
          key: 'paymentMethod.title',
          label: this.$t('Payment Method'),
          sortable: false,
        },
        { key: 'paymentDate', label: this.$t('Payment Date'), sortable: true },
        {
          key: 'creationDate',
          label: this.$t('Creation Date'),
          sortable: true,
        },
        { key: 'actions', label: this.$t('actions') },
      ]
    },
    formattedColumns() {
      return [
        { key: 'paymentDate', type: 'datetime' },
        { key: 'creationDate', type: 'datetime' },
      ]
    },
    formFields() {
      return [
        {
          id: 'user',
          type: 'hidden',
          initVal: { id: this.parentEntity.id },
          hidden: true,
        },
        {
          id: 'subscriptionPackage',
          object: true,
          type: 'select',
          initVal: this.parentEntity.userGroup,
          label: this.$t('Subscription'),
          options: this.subscriptionOptions,
          hidden: this.entity.congress,
        },
        {
          id: 'congress',
          object: true,
          type: 'select',
          label: this.$t('Congress'),
          options: this.congressOptions,
          hidden: this.entity.subscriptionPackage,
        },
        {
          id: 'amount',
          type: 'text',
          label: this.$t('Paid Amount'),
          required: true,
          rules: 'positive',
          colSize: 6,
        },
        {
          id: 'paymentMethod',
          object: true,
          type: 'select',
          label: this.$i18n.t('Payment Method'),
          options: this.paymentMethodOptions,
          required: true,
          colSize: 6,
        },
        {
          id: 'bankAccount',
          object: true,
          type: 'select',
          label: this.$t('Bank Account'),
          options: this.bankAccountOptions,
        },
        {
          id: 'paymentDate',
          type: 'datetime',
          label: this.$i18n.t('Payment Date'),
          required: true,
        },
        // {
        //   id: 'title',
        //   type: 'text',
        //   label: this.$t('title'),
        //   required: false,
        // },
        {
          id: 'comments',
          type: 'textarea',
          label: this.$t('comments'),
        },
      ]
    },
  },
  mounted() {
    this.loaded = true
    this.$store.dispatch('app-common/fetchPaymentMethods').then(response => {
      this.paymentMethodOptions = response.data
    })
    this.$store
      .dispatch('app-common/fetchPlainSubscriptions')
      .then(response => {
        this.subscriptionOptions = response.data
      })
    this.$store.dispatch('app-common/fetchCongresses').then(response => {
      this.congressOptions = response.data
    })
    this.$store.dispatch('app-common/fetchBankAccounts').then(response => {
      this.bankAccountOptions = response.data
    })
  },
  methods: {
    extraValidation(entity) {
      if (entity.paymentMethod.id == 4 && !entity.bankAccount) {
        return this.$t('Please enter the bank account for this transfer!')
      }
      return ''
    },
    updateEntity(newEntity) {
      this.entity = newEntity
    },
  },
}
</script>
